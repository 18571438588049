import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  //{
  //  path: '/',
  //  name: 'home',
  //import HomeView from '../views/HomeView.vue'
  //  component: HomeView
  //},
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "BearHome" */ '../views/BearCave/BearHome.vue')
  },
  {
    path: '/bear/heart',
    name: 'bearHeart',
    component: () => import(/* webpackChunkName: "bearCore" */ '../views/BearCave/BearHeart.vue')
  },
  {
    path: '/bear/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/BearCave/BearNews.vue')
  },
  {
    path: '/bear/facts',
    name: 'facts',
    component: () => import(/* webpackChunkName: "facts" */ '../views/BearCave/BearFacts.vue')
  },
  //// BEAR AUTO
  {
    path: '/bear/AutoControl',
    name: 'AutoControl',
    component: () => import(/* webpackChunkName: "AutoControl" */ '../views/BearCave/AutoControl.vue')
  },
  {
    path: '/bear/AutoComfort',
    name: 'AutoComfort',
    component: () => import(/* webpackChunkName: "AutoComfort" */ '../views/BearCave/AutoComfort.vue')
  },
  {
    path: '/bear/AutoLights',
    name: 'AutoLights',
    component: () => import(/* webpackChunkName: "AutoLights" */ '../views/BearCave/AutoLights.vue')
  },
  {
    path: '/bear/AutoSound',
    name: 'AutoSound',
    component: () => import(/* webpackChunkName: "AutoSound" */ '../views/BearCave/AutoSound.vue')
  },
  {
    path: '/bear/AutoEntertainment',
    name: 'AutoEntertainment',
    component: () => import(/* webpackChunkName: "AutoEntertainment" */ '../views/BearCave/AutoEntertainment.vue')
  },
  {
    path: '/bear/AutoSecurity',
    name: 'AutoSecurity',
    component: () => import(/* webpackChunkName: "AutoSecurity" */ '../views/BearCave/AutoSecurity.vue')
  },
  {
    path: '/bear/AutoEnergy',
    name: 'AutoEnergy',
    component: () => import(/* webpackChunkName: "AutoEnergy" */ '../views/BearCave/AutoEnergy.vue')
  },
  //// BEAR CAVE
  {
    path: '/bear',
    name: 'bearHome',
    component: () => import(/* webpackChunkName: "bearHome" */ '../views/BearCave/BearHome.vue')
  },
  {
    path: '/bear/cave',
    name: 'bearCave',
    component: () => import(/* webpackChunkName: "bearCave" */ '../views/BearCave/BearCave.vue')
  },
  {
    path: '/bear/estimate',
    name: 'bearEstimate',
    component: () => import(/* webpackChunkName: "bearEstimate" */ '../views/BearCave/BearEstimate.vue')
  },
  {
    path: '/bear/Quote',
    name: 'bearQuote',
    component: () => import(/* webpackChunkName: "bearQuote" */ '../views/BearCave/BearQuote.vue')
  },
  //// BEAR Woods
  {
    path: '/bear/Woods',
    name: 'BearWoods',
    component: () => import(/* webpackChunkName: "bearWoods" */ '../views/BearCave/BearWoods.vue')
  },
  // BEAR Upgrades
  {
    path: '/bear/premiumHardware',
    name: 'PremiumHardware',
    component: () => import(/* webpackChunkName: "premiumHardware" */ '../views/BearCave/PremiumHardware.vue')
  },
  {
    path: '/bear/rentalProperty',
    name: 'BearRentalProperty',
    component: () => import(/* webpackChunkName: "bearRentalProperty" */ '../views/BearCave/RentalProperty.vue')
  },
  {
    path: '/bear/outCare',
    name: 'BearOutCare',
    component: () => import(/* webpackChunkName: "bearOutCare" */ '../views/BearCave/BearOutCare.vue')
  },
  //rooms
  {
    path: '/bear/cave/rooms/CaveDen',
    name: 'CaveDen',
    component: () => import(/* webpackChunkName: "CaveDen" */ '../views/BearCave/rooms/CaveDen.vue')
  },
  {
    path: '/bear/cave/rooms/CaveEntrance',
    name: 'CaveEntrance',
    component: () => import(/* webpackChunkName: "CaveEntrance" */ '../views/BearCave/rooms/CaveEntrance.vue')
  },
  {
    path: '/bear/cave/rooms/CaveBedroom',
    name: 'CaveBedroom',
    component: () => import(/* webpackChunkName: "CaveBedroom" */ '../views/BearCave/rooms/CaveBedroom.vue')
  },
  {
    path: '/bear/cave/rooms/CaveHallway',
    name: 'CaveHallway',
    component: () => import(/* webpackChunkName: "CaveHallway" */ '../views/BearCave/rooms/CaveHallway.vue')
  },
  {
    path: '/bear/cave/rooms/CaveLivingRoom',
    name: 'CaveLivingRoom',
    component: () => import(/* webpackChunkName: "CaveLivingRoom" */ '../views/BearCave/rooms/CaveLivingRoom.vue')
  },
  {
    path: '/bear/cave/rooms/CaveKitchen',
    name: 'CaveKitchen',
    component: () => import(/* webpackChunkName: "CaveKitchen" */ '../views/BearCave/rooms/CaveKitchen.vue')
  },
  // woods
  {
    path: '/bear/cave/rooms/CaveGarage',
    name: 'CaveGarage',
    component: () => import(/* webpackChunkName: "CaveGarage" */ '../views/BearCave/rooms/CaveGarage.vue')
  },
  {
    path: '/bear/cave/rooms/CaveYard',
    name: 'CaveYard',
    component: () => import(/* webpackChunkName: "CaveYard" */ '../views/BearCave/rooms/CaveYard.vue')
  },
  // legal
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsOfService.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue')
  },
  // contact
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactRequest.vue')
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import(/* webpackChunkName: "roadmap" */ '../views/RoadMap.vue')
  },
  {
    path: '/planner',
    name: 'planner',
    component: () => import(/* webpackChunkName: "planner" */ '../views/Planner/BusinessPlanner.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServiceRetainer.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0, behavior: "smooth" };
  }
})

export default router
