import 'mutationobserver-shim'
import VueGtag from "vue-gtag";
import { createApp } from 'vue'

//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cookie from './plugins/cookie'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)

createApp(App)
    .use(cookie)
    .use(router)
    .use(VueGtag, { config: { id: "G-T0LWQCTDKV" } })
    .mount('#app')
